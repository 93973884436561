import axiosInstance from "./Api";

export default {
  
  //http://127.0.0.1:8003/api/dashboard/seller/1/ADMIN/16

  async getUserDashboard(type, uid, role_type, role_id) {
    let response = await axiosInstance().get(`/dashboard/${type}/${uid}/${role_type}/${role_id}`);
    return response;
  },

  async getAdmin(){
    let response = await axiosInstance().get(`/dashboard/admin`);
    return response;
  },

  async getItemsSold(payload) {
    console.log("getItemsSold payload",payload)
    let response = await axiosInstance().get(`/dashboard/item_sales?from=${payload.fromDate}&to=${payload.toDate}`);
  
    return response;
  },

  async getItemSalesStats() {
  
    let response = await axiosInstance().get(`/dashboard/montly_earning`);
  
    return response;
  },

};
