<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <!-- <b-col md="12 mt-2">
        <h2 class="text-black">Sale</h2>
      </b-col>

      <b-col xl="2" md="3" sm="3" 
            v-for="(data, index) in mainSaleStats"
            :key="index">
        <b-card align="center">

          <b-avatar class="m-2" size="48" :variant="data.color">
            <feather-icon size="18" :icon="data.icon" />
          </b-avatar>

          <h3>{{ data.data }}</h3>
          <h5>{{ data.title }}</h5>
        </b-card>
      </b-col> -->

      <!-- <b-col xl="12" md="12">
        <b-card v-if="user_role_id == 1">
          <b-card-header>
            <b-card-title>Stripe Vendor Account Link</b-card-title>
          </b-card-header>

          <b-card-body class="card-body">
            <b-row>
              <b-col xl="3" sm="6">
                <a :href="stripe_json.accountLink.url" target="_blank"
                  >Complete Stripe Account
                </a>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col> -->

      <!-- <b-col xl="12" md="12" v-if="user_role_id == 1 && itemCount.length == 0">
        <b-alert variant="primary" show>
          <h4 class="alert-heading">
            Hey welcome to Revivify Revivify Dashboard :)
          </h4>
          <div class="alert-body">
            <span
              >First time to sell you product on Revivify, Create your first
              product by clicking here!</span
            >
            <b-button
              class="ml-2"
              size="sm"
              variant="primary"
              :to="{ name: 'sell_refurbish' }"
            >
              Add Item
            </b-button>
          </div>
        </b-alert>
      </b-col> -->

      <b-col xl="12" md="12">
        <dashboard-sell-stats />
      </b-col>

      <b-col xl="12" md="12">
        <ecommerce-statistics-items />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="12">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="user_role_id == 1">
      <b-col lg="12">
        <seller-all-item-list v-if="user_role_id == 1" />
      </b-col>
    </b-row>

    <b-row class="match-height" v-if="user_role_id == 2">
      <b-col lg="12">
        <all-item-list v-if="user_role_id == 2" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BAlert,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import DashboardSellStats from "./DashboardSellStats.vue";
import DashboardRefurbishStats from "./DashboardRefurbishStats.vue";
import EcommerceStatisticsItems from "./EcommerceStatisticsItems.vue";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";
import AllItemList from "../Admin/ItemsList/AllItemList.vue";
import SellerAllItemList from "../SellerItems/SellerAllItemList.vue";
import ProductServices from "@/apiServices/ProductServices";
import storageService from "@/apiServices/storageService";
import DashboardServices from "@/apiServices/DashboardServices";
import StripeVendorServices from "@/apiServices/StripeVendorServices";

export default {
  metaInfo: {
    title: "Seller Dashboard | Revivify",
    htmlAttrs: {
      lang: "en-US",
    },
  },
  components: {
    BRow,
    BCol,
    BButton,

    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BAlert,

    DashboardSellStats,
    DashboardRefurbishStats,
    EcommerceStatisticsItems,
    EcommerceRevenueReport,
    AllItemList,
    SellerAllItemList,
  },
  data() {
    return {
      stripe_json: {},
      data: {},
      accountLink: "",
      user_role_id: 0,
      mainSaleStats: [
        {
          color: "light-primary",
          icon: "ShoppingCartIcon",
          data: "125",
          title: "Sales",
        },
        {
          color: "light-primary",
          icon: "CoffeeIcon",
          data: "100",
          title: "Credit",
        },
        {
          color: "light-primary",
          icon: "PieChartIcon",
          data: "$1599",
          title: "Total Earned",
        },
        {
          color: "light-primary",
          icon: "HeartIcon",
          data: "499",
          title: "Paid Promotions",
        },
      ],
      itemCount: null,
    };
  },

  beforeMount() {
    this.loadStatsDef();
  },
  methods: {
    loadStatsDef() {
      const profileData = storageService.UserData.getUserProfile();
      let userInfo = JSON.parse(profileData);
      let user_id = userInfo.user_id;
      this.user_role_id = parseInt(userInfo.user_role_id);

      if (this.user_role_id == 1) {
        //this.loadStripeAccountLink(user_id);
        this.loadItemCount(user_id);
      }

      // data
      /* this.$http.get('/ecommerce/data')
        .then(response => {
          this.data = response.data;
          const userData = getUserData()
          this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
        }) */
    },

    loadItemCount(user_id) {
      console.log("item_count");

      ProductServices.getSellerItems(user_id, "all")
        .then((response) => {
          this.itemCount = response.data.data;
          // if (this.user_role_id == 1 && this.itemCount.length == 0) {
          //   this.$router.push("/sell_refurbish");
          // }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },

    // loadStripeAccountLink(user_id) {
    //   console.log("loadStripeAccountLink");
    //   StripeVendorServices.getAccountLink(user_id)
    //     .then((response) => {
    //       this.stripe_json = JSON.parse(response.data.data.strpven_json_data);
    //       console.log(
    //         "stripe_json: ",
    //         JSON.parse(this.stripe_json.strpven_json_data)
    //       );
    //       //this.accountLink = JSON.parse(this.stripe_json.strpven_json_data)
    //     })
    //     .catch((error) => {
    //       this.errorMessage = error.message;
    //       console.error("There was an error! in notifications", error);
    //     });
    // },
  },
};
</script>

<style>
[dir] .b-avatar.badge-light-primary {
  background-color: #e5d5bc !important;
}

.b-avatar.badge-light-primary {
  color: #ffffff !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
